import React from 'react';
import { Switch as AriaSwitch, SwitchProps as AriaSwitchProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { composeTailwindRenderProps, focusRing } from '../../utils';
import { Tooltip, TooltipConfigProps, useToolTip } from '../Tooltip';

export interface SwitchProps extends Omit<AriaSwitchProps, 'children'> {
  children: React.ReactNode;
  tip?: TooltipConfigProps;
}

const track = tv({
  extend: focusRing,
  base: 'flex h-4 w-7 px-px items-center shrink-0 cursor-default rounded-full transition duration-200 ease-in-out shadow-inner border border-transparent cursor-pointer',
  variants: {
    color: {
      secondary: ''
    },
    isDisabled: {
      true: 'opacity-50'
    }
  },
  compoundVariants: [
    {
      color: 'secondary',
      isSelected: true,
      className: 'bg-black group-pressed/toggle:bg-gray-700'
    },
    {
      color: 'secondary',
      isSelected: false,
      className: 'bg-gray-300 group-hover/toggle:bg-gray-400 group-pressed/toggle:bg-gray-700'
    }
  ]
});

const handle = tv({
  base: 'h-3 w-3 transform rounded-full outline outline-1 -outline-offset-1 outline-transparent shadow transition duration-200 ease-in-out',
  variants: {
    color: {
      secondary: 'bg-white'
    },
    isSelected: {
      false: 'translate-x-0',
      true: 'translate-x-[100%]'
    },
    isDisabled: {
      true: 'forced-colors:outline-[GrayText]'
    }
  }
});

export const Switch: React.FC<SwitchProps> = (props) => {
  const { children, tip, ...rest } = props;

  const tooltip = useToolTip(tip);

  return (
    <>
      <AriaSwitch
        {...rest}
        data-tooltip-id={tooltip?.id}
        className={composeTailwindRenderProps(
          props.className,
          'group/toggle flex gap-2 items-center text-gray-800 disabled:text-gray-300 dark:text-zinc-200 dark:disabled:text-zinc-600 forced-colors:disabled:text-[GrayText] text-sm transition'
        )}
      >
        {(renderProps) => {
          return (
            <>
              <div className={track({ ...renderProps, color: 'secondary' })}>
                <span className={handle({ ...renderProps, color: 'secondary' })} />
              </div>
              {children}
            </>
          );
        }}
      </AriaSwitch>
      {tooltip ? <Tooltip {...tooltip}>{tooltip.content}</Tooltip> : null}
    </>
  );
};
