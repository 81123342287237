import { classNames } from '../../../utils/classNames';

export const getButtonBaseClasses = (props: {
  isDisabled?: boolean;
  isStatic?: boolean;
  clickArea?: boolean;
}) => {
  const { isDisabled, isStatic, clickArea } = props;

  return classNames(
    'font-semibold transition',
    isDisabled || isStatic ? 'cursor-default' : 'cursor-pointer',
    !isDisabled && !isStatic && clickArea && 'after:absolute after:inset-0',

    'focus-visible:outline-0 focus:outline-0',
    !isDisabled && 'focus-visible:ring-2 focus-visible:ring-primary-5 focus-visible:ring-offset-1'
  );
};
