import { getButtonPaddingClasses } from './getButtonPaddingClasses';
import { classNames } from '../../../utils/classNames';
import { ButtonColor, ButtonSide, ButtonSize, ButtonWeight } from '../types';

export const getButtonSizeClasses = (props: {
  size: ButtonSize;
  color?: ButtonColor;
  weight?: ButtonWeight;
  side?: ButtonSide;
  plain?: boolean;
  noBorder?: boolean;
  withIcon?: boolean;
  iconOnly?: boolean;
  disableExtraIconPadding?: boolean;
}) => {
  const {
    plain,
    size,
    color,
    weight,
    side,
    noBorder,
    withIcon,
    iconOnly,
    disableExtraIconPadding = false
  } = props;

  if (plain) {
    return '';
  }

  return classNames(
    {
      'text-xs': size === 'xs' || size === 'sm' || size === 'md',
      'text-sm': size === 'lg' || size === 'xl'
    },
    {
      // 'gap-x-1.5': size === 'xs',
      'gap-x-2': size === 'xs' || size === 'sm',
      'gap-x-2.5': size === 'md' || size === 'lg',
      'gap-x-3.5': size === 'xl'
    },
    {
      rounded: !side,
      'rounded-l': side === 'left',
      'rounded-r': side === 'right'
    },
    weight !== 'inline'
      ? getButtonPaddingClasses({
          size,
          color,
          noBorder,
          withIcon,
          iconOnly,
          disableExtraIconPadding
        })
      : null
  );
};
