import React from 'react';
import { DropdownLabel } from '../Dropdown';
import { IMenuItem } from '../Menu';

export interface SelectLabelProps {
  open?: boolean;
  prefix?: React.ReactNode;
  placeholder?: React.ReactNode;
  multiple?: boolean;
  selectedProperties: IMenuItem[];
}

export const SelectLabel: React.FC<SelectLabelProps> = (props) => {
  const { open, placeholder, prefix, selectedProperties, multiple } = props;

  const label =
    placeholder && !selectedProperties.length ? (
      <span className={open ? 'inherit' : 'text-gray-500'}>{placeholder}</span>
    ) : multiple ? (
      <div className={'flex items-center truncate gap-x-0.5'}>
        <span className={'truncate'}>{selectedProperties[0].label}</span>
        {selectedProperties.length > 1 ? (
          <span>
            {' + '}
            {selectedProperties.length - 1}
          </span>
        ) : null}
      </div>
    ) : (
      selectedProperties[0]?.label
    );

  return <DropdownLabel prefix={prefix} label={label} />;
};
