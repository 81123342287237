import React from 'react';
import { ForwardRef, isValidElementType } from 'react-is';
import { classNames } from '../../utils/classNames';

export interface IconProps {
  icon?: React.ReactNode | React.FC<any> | typeof ForwardRef;
  iconProps?: { className?: string };
}

export const Icon: React.FC<IconProps> = (props) => {
  const { icon, iconProps } = props;

  if (typeof icon === 'string') {
    return (
      <img
        className={classNames('w-4 h-4 shrink-0', iconProps?.className)}
        aria-hidden='true'
        src={icon}
      />
    );
  }

  if (isValidElementType(icon)) {
    const Component = icon;

    return (
      <Component {...iconProps} className={classNames('w-4 h-4 shrink-0', iconProps?.className)} />
    );
  }

  return icon as React.ReactNode;
};
