import React, { useContext } from 'react';
import { ToastState, useToastState } from '@react-stately/toast';
import { ToastRegion } from './ToastRegion';

const ToastContext = React.createContext<ToastState<{
  title: string;
  description: string;
}> | null>(null);

export const useToaster = () => {
  return useContext(ToastContext);
};

export function ToastProvider({ children, ...props }) {
  const state = useToastState<{
    title: string;
    description: string;
  }>({
    maxVisibleToasts: 5
  });

  return (
    <ToastContext.Provider value={state}>
      {typeof children === 'function' ? children(state) : children}
      {state.visibleToasts.length > 0 && <ToastRegion {...props} state={state} />}
    </ToastContext.Provider>
  );
}
