import { classNames } from '../../../utils/classNames';
import { ButtonColor, ButtonSize, ButtonWeight } from '../types';

export function getButtonPaddingClasses(props: {
  size?: ButtonSize;
  color?: ButtonColor;
  noBorder?: boolean;
  withIcon?: boolean;
  iconOnly?: boolean;
  disableExtraIconPadding?: boolean;
}) {
  const { size = 'sm', noBorder, color, withIcon, iconOnly, disableExtraIconPadding } = props;

  const needIncreasePadding =
    !disableExtraIconPadding && withIcon && !iconOnly && color === 'secondary';

  let pl;
  let pr;
  let py;

  if (size == 'xs') {
    py = noBorder ? 'py-1.5' : 'py-[5px]';
    pl = 'pl-2';
    pr = 'pr-2';
  } else if (size == 'sm') {
    py = noBorder ? 'py-1.5' : 'py-[5px]';
    pl = iconOnly ? 'pl-1.5' : 'pl-2.5';
    pr = iconOnly ? 'pr-1.5' : 'pr-2.5';
  } else if (size == 'md') {
    py = noBorder ? 'py-2' : 'py-[7px]';
    pl = iconOnly ? 'pl-2' : 'pl-3';
    pr = iconOnly ? 'pr-2' : needIncreasePadding ? 'pr-4' : 'pr-3';
  } else if (size == 'lg') {
    py = noBorder ? 'py-2' : 'py-[7px]';
    pl = iconOnly ? 'pl-2' : 'pl-3';
    pr = iconOnly ? 'pr-2' : needIncreasePadding ? 'pr-4' : 'pr-3';
  } else if (size == 'xl') {
    py = noBorder ? 'py-2.5' : 'py-[9px]';
    pl = iconOnly ? 'pl-3' : 'pl-4';
    pr = iconOnly ? 'pr-3' : needIncreasePadding ? 'pr-5' : 'pr-4';
  }

  return classNames(pl, pr, py);
}
