import React from 'react';
import { classNames } from '../../utils/classNames';

export interface SidebarResizeHandlerProps {
  onResize: () => void;
  isResizing?: boolean;
}
export const SidebarResizeHandler: React.FC<SidebarResizeHandlerProps> = (props) => {
  const { isResizing, onResize, ...rest } = props;

  return (
    <div
      className={classNames(
        'absolute top-0 left-0 bottom-0 w-1 cursor-col-resize select-none touch-none hover:bg-primary-5 transition',
        isResizing ? 'bg-primary-5' : 'bg-transparent'
      )}
      onPointerDown={onResize}
      {...rest}
    />
  );
};
