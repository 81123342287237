import { AvatarSize } from './types';

export const classNameByAvatarSize: Record<AvatarSize, string> = {
  sm: 'h-4 w-4 text-[10px]',
  md: 'h-5 w-5 text-xs',
  lg: 'h-6 w-6 text-xs',
  xl: 'h-8 w-8 text-sm',
  '2xl': 'h-10 w-10 text-sm',
  '3xl': 'h-16 w-16 text-sm'
};
