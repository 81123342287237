import React, { useMemo } from 'react';
import { SelectOption } from './types';
import { classNames } from '../../utils';
import { IMenuItem } from '../Menu';

export const useSelectItems = (props: { options: SelectOption[] }): IMenuItem[] => {
  const { options } = props;

  const items: IMenuItem[] = useMemo(
    () =>
      options.map(({ value, label, imgSrc: Icon, ...rest }) => ({
        id: value,
        searchKey: [typeof label === 'string' ? label : '', value].join(''),
        label: (
          <div className={'flex flex-1 gap-x-2 items-center truncate max-w-full'}>
            {typeof Icon === 'string' ? (
              <img className='h-4 w-4 text-gray-500' aria-hidden='true' src={Icon} />
            ) : typeof Icon === 'function' ? (
              <Icon className='h-4 w-4 text-gray-500' aria-hidden='true' />
            ) : (
              Icon
            )}
            {!label || typeof label === 'string' ? (
              <span className={classNames('truncate', !label && 'opacity-0')}>
                {label || 'No value'}
              </span>
            ) : (
              label
            )}
          </div>
        ),
        ...rest
      })),
    [options]
  );

  return items;
};
