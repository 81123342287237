import React from 'react';
import { classNames } from '../../utils/classNames';

export interface DropdownLabelProps {
  label: React.ReactNode;
  icon?: React.ReactNode | React.FC<any>;
  prefix?: React.ReactNode;
}

export const DropdownLabel: React.FC<DropdownLabelProps> = (props) => {
  const { icon, prefix, label } = props;

  return (
    <>
      {typeof icon === 'string' ? (
        <img className='h-4 w-4 mr-2' aria-hidden='true' src={icon} />
      ) : typeof icon === 'function' ? (
        icon({ className: 'h-4 w-4 mr-2', 'aria-hidden': 'true' })
      ) : (
        icon
      )}
      {prefix && <span className='text-gray-500 mr-1'>{prefix}</span>}
      {!label || typeof label === 'string' ? (
        <span className={classNames('truncate', !label && 'opacity-0')}>{label || 'No value'}</span>
      ) : (
        label
      )}
    </>
  );
};
