import React from 'react';
import { ModalContainer, ModalContainerProps } from '../Modal';

export type SidebarContainerProps = ModalContainerProps;

export const SidebarContainer: React.FC<SidebarContainerProps> = (props) => {
  return (
    <ModalContainer
      width={props.wFull ? undefined : '300px'}
      position={'left'}
      animate={'slide'}
      hFull
      {...props}
    />
  );
};
