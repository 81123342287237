import { classNames } from '../../../utils/classNames';
import { ButtonJustify } from '../types';

export const getButtonLayoutClasses = (props: {
  wFull?: boolean;
  flex?: boolean;
  justify?: ButtonJustify;
}) => {
  const { wFull, flex, justify } = props;

  return classNames(
    'items-center whitespace-nowrap max-w-full',
    wFull && 'w-full',
    flex && 'inline-flex',
    {
      'justify-start': justify === 'start',
      'justify-center': justify === 'center',
      'justify-end': justify === 'end',
      'justify-between': justify === 'between'
    }
  );
};
