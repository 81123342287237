import { nanoid } from 'nanoid';
import React, { Children, useState } from 'react';
import { Avatar, AvatarProps } from './AvatarInitials';
import { classNames } from '../../utils/classNames';
import { Tooltip } from '../Tooltip';

export type AvatarStackProps = {
  /**
   * Size of the avatar
   * @default 'lg'
   * @type 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
   * @optional
   */
  size?: AvatarProps['size'];
  /**
   * Total number of avatars
   * @type number
   * @optional
   */
  total?: number;
  /**
   * Maximum number of avatars to display
   * @type number
   * @optional
   * @default 5
   */
  limit?: number;
  /**
   * Classname for the avatar
   * @type string
   * @optional
   */
  avatarClassName?: string;
  /**
   * Default color for the avatar
   * @type string
   * @optional
   * @default 'bg-neutral-1'
   */
  defaultColor?: string;
  /**
   * Component to use for the avatar
   * @type React.FC<Partial<AvatarProps>>
   * @optional
   * @default Avatar
   */
  Component?: React.FC<Partial<AvatarProps>>;
  /**
   * Children to render
   * @type React.ReactNode
   * @optional
   */
  children: any;
};

export const AvatarStack: React.FC<AvatarStackProps> = (props) => {
  const {
    size = 'lg',
    avatarClassName,
    Component = Avatar,
    children,
    limit = 5,
    defaultColor = 'bg-neutral-1'
  } = props;

  const arrayChildren = Children.toArray(children).filter(Boolean);

  const limitedArrayChildren =
    arrayChildren.length > limit ? arrayChildren.slice(0, limit) : arrayChildren;

  const { total = arrayChildren.length } = props;

  const left = total ? total - limit : 0;

  return (
    <div
      data-testid='avatar-stack'
      className={classNames('relative inline-flex', {})}
      style={{ direction: 'rtl' }}
    >
      {left > 0 ? (
        <Component
          size={size}
          className={classNames(avatarClassName, 'z-10 outline outline-white shadow-md -ml-0.5')}
          defaultColor={defaultColor}
        >
          <span className={'text-[10px] font-semibold'} style={{ direction: 'ltr' }}>
            +{Math.min(99, left)}
          </span>
        </Component>
      ) : null}
      {Children.map(limitedArrayChildren, (child, index) => {
        // @ts-expect-error Property 'props' does not exist on type 'string'.
        const id = child.props.id;

        const tooltipId = nanoid();

        return (
          <>
            <Component
              id={id}
              key={id || index}
              size={size}
              className={classNames(
                avatarClassName,
                'z-10 outline outline-white shadow-md',
                index === limitedArrayChildren.length - 1 ? '' : '-ml-1'
              )}
              defaultColor={defaultColor}
              data-testid={`avatar-${id || index}`}
              data-tooltip-id={`avatar-${id || index}-${tooltipId}`}
            >
              {child}
            </Component>

            <Tooltip
              id={`avatar-${id || index}-${tooltipId}`}
              positionStrategy={'fixed'}
              style={{ direction: 'ltr' }}
            >
              {/* @ts-expect-error Property 'props' does not exist on type 'string'. */}
              {child.props.tooltip}
            </Tooltip>
          </>
        );
      })}
    </div>
  );
};
