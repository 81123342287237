import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { Button, ButtonProps } from '../Button';

export const FormFieldHelp: React.FC<
  Omit<ButtonProps, 'children'> & { children: React.ReactNode }
> = (props) => {
  return (
    <Button
      plain
      isStatic
      icon={QuestionMarkCircleIcon}
      iconProps={{ className: 'text-gray-400' }}
      tip={props.children}
      tipProps={{
        place: 'bottom',
        positionStrategy: 'fixed'
      }}
    />
  );
};
