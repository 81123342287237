'use client';
import React from 'react';
import type { TabListProps, TabPanelProps, TabProps, TabsProps } from 'react-aria-components';
import {
  Tab as BaseTab,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel,
  Tabs as BaseTabs,
  composeRenderProps
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { classNames, focusRing } from '../../utils';

export type { TabPanelProps, TabProps, TabsProps, TabListProps };

const tabsStyles = tv({
  base: 'flex gap-4',
  variants: {
    orientation: {
      horizontal: 'flex-col',
      vertical: 'flex-row w-[800px]'
    }
  }
});

export function Tabs(props: TabsProps) {
  return (
    <BaseTabs
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabsStyles({ ...renderProps, className })
      )}
    />
  );
}

const tabListStyles = tv({
  base: 'flex gap-2 items-center w-full',
  variants: {
    orientation: {
      horizontal: 'flex-row',
      vertical: 'flex-col items-start'
    },
    align: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end'
    },
    withBorder: {
      true: 'border-b'
    }
  }
});

export function TabList<T extends object>(props: TabListProps<T>) {
  return (
    <BaseTabList
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabListStyles({ ...renderProps, className })
      )}
    />
  );
}

const tabProps = tv({
  extend: focusRing,
  base: 'flex items-center cursor-default font-medium transition forced-color-adjust-none text-center border-b border-b-2',
  variants: {
    size: {
      md: 'py-2.5 px-2 text-sm leading-6'
    },
    isSelected: {
      false: classNames(
        'border-transparent cursor-pointer',
        'text-gray-600 dark:text-gray-600',
        'hover:text-gray-700 dark:hover:text-gray-700',
        'hover:border-primary-2',
        'pressed:text-gray-700 dark:pressed:text-gray-700',
        'pressed:bg-white/10 dark:pressed:bg-white/10'
      ),
      true: 'border-primary-5'
    },
    isDisabled: {
      true: 'opacity-50'
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

export const Tab: React.FC<TabProps> = (props) => {
  return (
    <BaseTab
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabProps({ ...renderProps, className })
      )}
    />
  );
};

const tabPanelStyles = tv({
  extend: focusRing,
  base: 'flex-1 p-4 text-sm text-gray-900 dark:text-zinc-100'
});

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  return (
    <BaseTabPanel
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className })
      )}
    />
  );
};
