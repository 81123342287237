const normalizeNumber = (number: number, min: number, max: number): number =>
  Math.floor((number % (max - min)) + min);

export const generateBackgroundColor = (id: number): string =>
  backgroundColors[normalizeNumber(id, 0, backgroundColors.length)];

export const backgroundColors = [
  'bg-zinc-500',
  'bg-zinc-700',
  'bg-zinc-900',
  'bg-stone-500',
  'bg-stone-700',
  'bg-stone-900',
  'bg-red-500',
  'bg-red-700',
  'bg-red-900',
  'bg-orange-500',
  'bg-orange-700',
  'bg-orange-900',
  'bg-amber-500',
  'bg-amber-700',
  'bg-amber-900',
  'bg-yellow-500',
  'bg-yellow-700',
  'bg-yellow-900',
  'bg-lime-500',
  'bg-lime-700',
  'bg-lime-900',
  'bg-green-500',
  'bg-green-700',
  'bg-green-900',
  'bg-emerald-500',
  'bg-emerald-700',
  'bg-emerald-900',
  'bg-teal-500',
  'bg-teal-700',
  'bg-teal-900',
  'bg-cyan-500',
  'bg-cyan-700',
  'bg-cyan-900',
  'bg-sky-500',
  'bg-sky-700',
  'bg-sky-900',
  'bg-blue-500',
  'bg-blue-700',
  'bg-blue-900',
  'bg-indigo-500',
  'bg-indigo-700',
  'bg-indigo-900',
  'bg-violet-500',
  'bg-violet-700',
  'bg-violet-900',
  'bg-purple-500',
  'bg-purple-700',
  'bg-purple-900',
  'bg-fuchsia-500',
  'bg-fuchsia-700',
  'bg-fuchsia-900',
  'bg-pink-500',
  'bg-pink-700',
  'bg-pink-900',
  'bg-rose-500',
  'bg-rose-700',
  'bg-rose-900'
];
