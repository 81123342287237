'use client';
import { useEffect, useState } from 'react';

export const useCurrentTime = (props?: {
  time?: string | number | Date;
  now?: string | number | Date;
  live?: boolean;
  refreshCycle?: number;
}) => {
  const { time, now = Date.now(), live = !time, refreshCycle = 1000 } = props || {};

  const [currentTime, setCurrentTime] = useState(() => (time ? new Date(time).getTime() : now));

  useEffect(() => {
    if (!live) {
      return;
    }

    const timerId = setInterval(() => setCurrentTime(Date.now()), refreshCycle);

    return () => clearInterval(timerId);
  }, [live, refreshCycle]);

  return currentTime;
};
