import { XMarkIcon } from '@heroicons/react/20/solid';
import type { AriaToastProps } from '@react-aria/toast';
import { useToast } from '@react-aria/toast';
import { ToastState } from '@react-stately/toast';
import React from 'react';
import { classNames } from '../../utils/classNames';
import { Button } from '../Button';

export type IToast = {
  title: string;
  description: string;
};

export interface ToastProps extends AriaToastProps<IToast> {
  state: ToastState<IToast>;
}

export function Toast<T extends React.ReactNode>({ state, ...props }: ToastProps) {
  const { toast } = props;

  const ref = React.useRef(null);

  const { toastProps, titleProps, descriptionProps, closeButtonProps } = useToast(
    props,
    state,
    ref
  );

  return (
    <div
      {...toastProps}
      ref={ref}
      className={classNames(
        'toast flex items-center gap-x-4 bg-black text-white py-3 px-4 rounded',
        {
          'animate-in slide-in': toast.animation === 'entering',
          'animate-in fade-in': toast.animation === 'queued',
          'animate-out slide-out': toast.animation === 'exiting'
        }
      )}
    >
      <div {...titleProps}>{toast.content.title}</div>
      <div {...descriptionProps}>{toast.content.description}</div>
      <Button icon={XMarkIcon} {...closeButtonProps} className={'text-white'} />
    </div>
  );
}
