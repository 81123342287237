'use client';
import { useCallback, useState } from 'react';

declare let window: Window;

export const useLocalStorage = <T,>(
  key: string,
  initialValue: T,
  opts: { generation?: number } = {}
) => {
  const generation = opts?.generation;

  const [storedValue, setStoredValue] = useState<T>(() =>
    getInitialState(initialValue, key, generation)
  );

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        return setStoredValue((storedValue) => {
          const nextValue = value instanceof Function ? value(storedValue) : value;

          if (window && window.localStorage) {
            localStorage.setItem(
              key,
              JSON.stringify({
                generation,
                value: nextValue
              })
            );
          }

          return nextValue;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [key, generation]
  );

  const sync = useCallback(() => {
    const initialState = getInitialState(initialValue, key, generation);

    setStoredValue(initialState);
  }, [generation, initialValue, key]);

  return [storedValue, setValue, sync] as const;
};

const getInitialState = <T,>(initialValue: T, key: string, generation?: number): T => {
  try {
    if (typeof window === 'undefined') {
      // console.warn('Can`t use local storage with SSR. Initial value will be used.');

      return initialValue;
    }

    let item = window && window.localStorage.getItem(key);

    if (item) {
      item = JSON.parse(item);

      if (typeof generation === 'undefined' || item?.generation === generation) {
        return item.value;
      }
    }

    return initialValue;
  } catch (error) {
    console.error(error);

    return initialValue;
  }
};

export default useLocalStorage;
