import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Header, Section } from 'react-aria-components';
import { Button } from '#/packages/ui';
import { MenuButtonItem, MenuItem } from './MenuItem';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { MenuItems, MenuKey, MenuSelectionMode } from './types';
import { classNames } from '../../utils/classNames';

export interface MenuSectionProps {
  size?: 'sm' | 'md' | 'lg';
  items: MenuItems;
  hidden?: boolean;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  header?: React.ReactNode;
  selectionMode?: MenuSelectionMode;
  withCheckMark?: boolean;
  MenuSectionHeaderComponent?: React.FC<{
    group: React.ReactNode;
  }>;
  className?: string;
}

export const MenuSectionHeader = (props) => {
  const { group, headers } = props;

  const finalHeader = headers ? headers[group] : group;

  return finalHeader ? (
    <Header className={'mb-1 mt-2 mx-2 text-xs text-gray-500 border border-transparent'}>
      {finalHeader}
    </Header>
  ) : null;
};

export const MenuSection: React.FC<MenuSectionProps> = (props) => {
  const {
    header,
    items,
    hiddenKeys = [],
    selectionMode,
    withCheckMark,
    size,
    className,
    MenuSectionHeaderComponent = MenuSectionHeader
  } = props;

  const isHidden = items.every(
    (item) =>
      item !== MenuSeparatorPlaceholder && Array.from(hiddenKeys).includes(item.id as MenuKey)
  );

  return (
    <Section className={classNames('flex flex-col', isHidden ? 'hidden' : 'mx-2 my-1', className)}>
      {header ? <MenuSectionHeaderComponent group={header} /> : null}
      {items.map((item, i) =>
        item === MenuSeparatorPlaceholder ? (
          <MenuSeparator key={i} />
        ) : item.onClick || item.to ? (
          <MenuButtonItem
            size={size}
            key={item.id}
            item={item}
            hidden={Array.from(hiddenKeys).includes(item.id as MenuKey)}
          />
        ) : (
          <MenuItem
            key={item.id}
            item={item}
            size={size}
            hidden={Array.from(hiddenKeys).includes(item.id as MenuKey)}
            selectionMode={selectionMode}
            withCheckMark={withCheckMark}
          />
        )
      )}
    </Section>
  );
};

export const MenuSectionCollapsible: React.FC<MenuSectionProps> = (props) => {
  const {
    header,
    items,
    hiddenKeys = [],
    selectionMode,
    withCheckMark,
    size,
    className,
    MenuSectionHeaderComponent = MenuSectionHeader
  } = props;

  const isHidden = items.every(
    (item) =>
      item !== MenuSeparatorPlaceholder && Array.from(hiddenKeys).includes(item.id as MenuKey)
  );

  const [expanded, setExpanded] = useState(true);

  return (
    <Section
      className={classNames(
        'flex flex-col',
        'border border-top border-gray-200',
        isHidden ? 'hidden' : 'mx-2 my-0',
        className
      )}
    >
      <Button onClick={() => setExpanded((prev) => !prev)}>
        {header ? <MenuSectionHeaderComponent group={header} /> : null}
      </Button>
      <AnimatePresence>
        {expanded ? (
          <motion.div
            layout
            initial={{ height: 0 }}
            animate={{ height: 'fit-content' }}
            exit={{ height: 0 }}
          >
            {items.map((item, i) =>
              item === MenuSeparatorPlaceholder ? (
                <MenuSeparator key={i} />
              ) : item.onClick || item.to ? (
                <MenuButtonItem
                  size={size}
                  key={item.id}
                  item={item}
                  hidden={Array.from(hiddenKeys).includes(item.id as MenuKey)}
                />
              ) : (
                <MenuItem
                  key={item.id}
                  item={item}
                  size={size}
                  hidden={Array.from(hiddenKeys).includes(item.id as MenuKey)}
                  selectionMode={selectionMode}
                  withCheckMark={withCheckMark}
                />
              )
            )}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Section>
  );
};
