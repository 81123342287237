'use client';

import { motion } from 'framer-motion';
import React from 'react';
import { Loader } from './Loader';
import { classNames } from '../../utils/classNames';

export interface LoaderOverlayProps {
  size?: 'small' | 'medium' | 'large';
  isLoading?: boolean;
  isInitialLoading?: boolean;
  duration?: number;
  delay?: number;
  className?: string;
}

export const LoaderOverlay: React.FC<LoaderOverlayProps> = React.memo((props) => {
  const {
    size = 'large',
    duration = 0.35,
    delay = 0.35,
    className,
    isLoading,
    isInitialLoading
  } = props;

  const finalDelay = isInitialLoading ? 0 : delay;

  return isLoading || isInitialLoading ? (
    <motion.div
      className={classNames('bg-white bg-opacity-75 opacity-0 absolute z-40 inset-0', className)}
      transition={{
        duration,
        delay: finalDelay
      }}
      initial={{ opacity: isInitialLoading ? 1 : 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <Loader size={size} />
        {/*<div className='text-sm text-center mt-2 '>{statusText} </div>*/}
        {/*<div className='text-xs text-center'>{statusPercentage}</div>*/}
      </div>
    </motion.div>
  ) : null;
});

function parseToNaturalLanguage(s: string) {
  // Split by underscore
  const words = s.split('_');

  // Convert to title case
  const titleCased = words
    .map((word: string, index: number) => {
      if (index == 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(' ');

  // Check for certain keywords to add ellipsis or other punctuation
  if (titleCased.includes('Fetching')) {
    return titleCased + '...';
  } else if (titleCased.includes('Completed')) {
    return titleCased;
  } else {
    return titleCased;
  }
}
