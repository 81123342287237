import React, { useMemo } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { Button } from '../Button';
import { getPageNavOptions } from './getPageNavOptions';

export interface PaginationProps {
  pageIndex: number;
  pageSize: number;
  total: number;
  onChange: (index: number) => void;
}

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { pageIndex, pageSize, total, onChange } = props;

  const currentPageIndex = pageIndex;

  const lastPage = Math.floor(total / pageSize);
  const rest = lastPage - currentPageIndex;

  const pageNavOptions = useMemo<(number | 'gap')[]>(() => {
    return getPageNavOptions({ pageIndex, pageSize, total });
  }, [pageIndex, pageSize, total]);

  return (
    <div className={'flex items-center gap-2'}>
      <div className={'flex items-baseline gap-2'}>
        {pageNavOptions.map((pageIndex, i) =>
          pageIndex === 'gap' ? (
            <span key={i} className={'w-7 text-center text-lg font-medium leading-3'}>
              ...
            </span>
          ) : (
            <Button
              key={`${pageIndex}-page`}
              color={'tertiary'}
              weight={'ghost'}
              onClick={() => onChange(pageIndex)}
              isActive={currentPageIndex === pageIndex}
              isStatic={currentPageIndex === pageIndex}
            >
              {pageIndex + 1}
            </Button>
          )
        )}
      </div>
      <div className={'flex items-center'}>
        <Button
          color={'tertiary'}
          weight={'ghost'}
          icon={ArrowLeftIcon}
          isDisabled={currentPageIndex === 0}
          onClick={() => onChange(currentPageIndex - 1)}
        />
        <Button
          color={'tertiary'}
          weight={'ghost'}
          icon={ArrowRightIcon}
          isDisabled={rest === 0}
          onClick={() => onChange(currentPageIndex + 1)}
        />
      </div>
    </div>
  );
};
