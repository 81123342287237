export const getPageNavOptions = (props: {
  pageIndex: number;
  pageSize: number;
  total: number;
}): (number | 'gap')[] => {
  const { pageIndex, pageSize, total } = props;

  const lastPage = Math.ceil(total / pageSize) - 1;
  const rest = lastPage - pageIndex;

  return [
    pageIndex === 3 && rest === 0 ? 0 : pageIndex > 2 ? [0, 'gap'] : null,
    pageIndex === 2 || (rest === 0 && pageIndex > 1) ? pageIndex - 2 : null,
    pageIndex > 0 ? pageIndex - 1 : null,
    pageIndex,
    rest > 0 ? pageIndex + 1 : null,
    rest === 2 || (pageIndex === 0 && rest > 1) ? pageIndex + 2 : null,
    rest === 3 && pageIndex === 0 ? lastPage : rest > 2 ? ['gap', lastPage] : null
  ]
    .flat()
    .filter((item) => item !== null) as (number | 'gap')[];
};
