'use client';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  formatDistanceStrict
} from 'date-fns';

export const roundFormatDistance = (zonedTime: number | Date, now: number | Date = Date.now()) => {
  const minutesDiff = Math.abs(differenceInMinutes(now, zonedTime));

  if (minutesDiff < 1) {
    return 'now';
  }

  const hoursDiff = Math.abs(differenceInHours(now, zonedTime, { roundingMethod: 'round' }));

  if (hoursDiff < 1) {
    return `${minutesDiff}m`;
  }

  const daysDiff = Math.abs(differenceInDays(now, zonedTime));

  if (daysDiff < 1) {
    return `${hoursDiff}h`;
  }

  return formatDistanceStrict(now, zonedTime);
};
