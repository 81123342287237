'use client';
import React, { useEffect, useRef } from 'react';

export const useMenuAutoFocusSearchField = (props: {
  autoFocus?: boolean;
}): { ref: React.Ref<HTMLInputElement> } => {
  const { autoFocus } = props;

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const focus = () => ref.current?.focus();
    const timeout = setTimeout(focus, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [autoFocus, ref.current]);

  return {
    ref
  };
};
