import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import {
  MenuItem as BaseMenuItem,
  MenuItemProps as BaseMenuItemProps
} from 'react-aria-components';
import { IMenuItem, MenuSelectionMode } from './types';
import { classNames } from '../../utils/classNames';
import { Button, ButtonProps } from '../Button';
import { Checkbox } from '../Checkbox';
import { Tooltip } from '../Tooltip';

export interface MenuItemProps {
  size?: 'sm' | 'md' | 'lg';
  item: IMenuItem;
  hidden?: boolean;
  selectionMode?: MenuSelectionMode;
  withCheckMark?: boolean;
  className?: string;
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { item, hidden, selectionMode, size, className } = props;
  const { id, disabled, icon, buttonProps, ...rest } = item;

  return (
    <MenuItemContainer
      item={item}
      hidden={hidden}
      selectionMode={selectionMode}
      className={className}
    >
      {({ isSelected, isDisabled }) => (
        <MenuItemButton
          {...rest}
          {...buttonProps}
          size={size}
          id={`check-${id}`}
          selected={isSelected}
          disabled={isDisabled || disabled}
          selectionMode={selectionMode}
          className={
            selectionMode === 'multiple'
              ? classNames(buttonProps?.className, 'group/checkbox')
              : buttonProps?.className
          }
          icon={
            selectionMode !== 'multiple' ? (
              icon
            ) : (
              <Checkbox color={'tertiary'} id={`check-${id}`} isSelected={isSelected} />
            )
          }
        >
          <MenuItemLabel item={item} />
        </MenuItemButton>
      )}
    </MenuItemContainer>
  );
};

export const MenuButtonItem: React.FC<MenuItemProps> = (props) => {
  const { item, hidden, selectionMode, className, size } = props;
  const { id, current, disabled, buttonProps, ...rest } = item;

  return (
    <MenuItemContainer
      item={item}
      hidden={hidden}
      selectionMode={selectionMode}
      className={className}
    >
      {({ isSelected, isDisabled }) => (
        <MenuItemButton
          {...rest}
          {...buttonProps}
          size={size}
          id={`check-${id}`}
          selected={isSelected || current}
          disabled={isDisabled || disabled}
          iconProps={{
            className: 'text-gray-500'
          }}
          className={classNames(buttonProps?.className, 'font-medium')}
        >
          <MenuItemLabel item={item} />
        </MenuItemButton>
      )}
    </MenuItemContainer>
  );
};

const MenuItemButton: React.FC<
  ButtonProps & {
    selected?: boolean;
    disabled?: boolean;
    selectionMode?: MenuSelectionMode;
  }
> = (props) => {
  const { selected, disabled, selectionMode, className, size = 'sm', ...rest } = props;

  return (
    <Button
      color={'neutral'}
      weight={'ghost'}
      size={size}
      wFull
      flex
      isStatic={selected && selectionMode !== 'multiple'}
      isDisabled={disabled}
      className={classNames(
        'group relative flex items-center hover:bg-transparent hover:border-transparent outline-none text-sm px-2 transition-none',
        { 'py-4 px-4': size === 'lg' },
        { 'hover:bg-black/5': !selected || selectionMode === 'multiple' },
        selected ? 'font-medium' : 'font-normal',
        disabled && 'text-gray-400',
        className
      )}
      {...rest}
    />
  );
};

export const MenuItemContainer: React.FC<MenuItemProps & BaseMenuItemProps> = (props) => {
  const { item, hidden, selectionMode, className, ...rest } = props;

  return (
    <BaseMenuItem
      id={item.id}
      className={({ isDisabled, isSelected }) =>
        classNames(
          'outline-none',
          { hidden: hidden },
          (isSelected && selectionMode === 'single') || isDisabled
            ? 'cursor-default'
            : 'cursor-pointer',
          className
        )
      }
      {...rest}
    />
  );
};

export const MenuItemLabel: React.FC<MenuItemProps> = (props) => {
  const { item } = props;

  return (
    <div className={'flex flex-1 justify-between items-center overflow-x-hidden '}>
      {typeof item.label === 'string' ? (
        <span className={'truncate'}>{item.label}</span>
      ) : (
        item.label
      )}
      {item.extra || item.tooltip ? (
        <div className={'flex items-center gap-x-2'}>
          {item.extra}
          {item.tooltip ? (
            <>
              <InformationCircleIcon
                data-tooltip-id={`menu-item-tooltip-${item.id}`}
                className={'w-4 h-4 ml-6 text-gray-400 hover:text-gray-900'}
              />
              <Tooltip
                id={`menu-item-tooltip-${item.id}`}
                place={'right'}
                positionStrategy={'fixed'}
              >
                {item.tooltip}
              </Tooltip>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
