'use client';
import { getYear } from 'date-fns';
import { format as formatDate, OptionsWithTZ, utcToZonedTime } from 'date-fns-tz';
import { useCurrentTime } from '../useCurrentTime';

export type useTimeStampProps = {
  time?: Date | number | string;
  timeZone?: OptionsWithTZ['timeZone'];
  divider?: string;
  weekDayFormat?: string | null;
  hideCurrentYear?: boolean;
  dateFormat?: string | null;
  timeFormat?: string | null;
  zoneFormat?: string | null;
};

export const useTimeStamp = (props: useTimeStampProps) => {
  const {
    time,
    timeZone,
    timeFormat = 'HH:mm:ss',
    zoneFormat = '(z)',
    weekDayFormat = '',
    hideCurrentYear = false,
    divider = ' • '
  } = props;

  const now = Date.now();

  const currentTime = useCurrentTime({ time, now });
  const zonedTime = timeZone ? utcToZonedTime(currentTime, timeZone) : currentTime;
  const zonedNow = timeZone ? utcToZonedTime(Date.now(), timeZone) : now;

  const isCurrentYear = getYear(zonedNow) === getYear(new Date(zonedTime));
  const defaultDateFormat = [
    weekDayFormat,
    isCurrentYear && hideCurrentYear ? 'MMM dd' : 'MMM dd, yyyy'
  ]
    .filter(Boolean)
    .join(' ');

  const { dateFormat = defaultDateFormat } = props;

  const dateLabel = dateFormat ? formatDate(zonedTime, dateFormat, { timeZone }) : '';
  const timeLabel = timeFormat ? formatDate(zonedTime, timeFormat, { timeZone }) : '';
  const zoneLabel = zoneFormat ? formatDate(zonedTime, zoneFormat, { timeZone }) : '';

  return {
    timestamp: [dateLabel, timeLabel].filter(Boolean).join(divider),
    zone: zoneLabel
  };
};
