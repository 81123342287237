export interface DateFormat {
  day?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  year?: 'numeric' | '2-digit';
  locale?: string;
  timeZone?: string;
}

export function formatDateRange<DateType extends Date>(
  from: DateType,
  to: DateType,
  opts?: DateFormat
): string | undefined {
  const defaultFormat: DateFormat = {
    locale: 'en-GB',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  };
  const { locale, ...format } = opts || {};
  const mergedFormats: DateFormat = { ...defaultFormat, ...format };

  const fromFormattedParts: Intl.DateTimeFormatPart[] = new Intl.DateTimeFormat(
    locale,
    mergedFormats
  )
    .formatToParts(from)
    .filter((part) => part.type !== 'literal');

  const toFormattedParts: Intl.DateTimeFormatPart[] = new Intl.DateTimeFormat(locale, mergedFormats)
    .formatToParts(to)
    .filter((part) => part.type !== 'literal');

  if (fromFormattedParts.length > 3 || toFormattedParts.length > 3) {
    console.error('Unexpected length of formatted parts array.');
    return undefined;
  }

  const datePartsType: string[] = fromFormattedParts.map((part) => part.type);

  if (
    fromFormattedParts.map((i) => i.value).join('') ===
    toFormattedParts.map((i) => i.value).join('')
  ) {
    return datePartsType
      .map((type) => [
        type === 'year' ? ', ' : ' ',
        getDatePartValueByType(type, fromFormattedParts)
      ])
      .flat()
      .filter(Boolean)
      .join('');
  }

  if (from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear()) {
    const fromFormat = getDatePartValueByType('day', fromFormattedParts);
    const toFormat = getDatePartValueByType('day', toFormattedParts);

    return datePartsType
      .map((type) => [
        type === 'year' ? ', ' : ' ',
        type === 'day'
          ? `${fromFormat} - ${toFormat}`
          : getDatePartValueByType(type, fromFormattedParts)
      ])
      .flat()
      .filter(Boolean)
      .join('');
  }

  if (from.getFullYear() === to.getFullYear()) {
    const fromFormat: string[] = [];
    const toFormat: string[] = [];

    datePartsType
      .filter((type) => type !== 'year')
      .forEach((type) => {
        fromFormat.push(' ', getDatePartValueByType(type, fromFormattedParts));
        toFormat.push(' ', getDatePartValueByType(type, toFormattedParts));
      });

    return `${fromFormat.filter(Boolean).join('')} - ${toFormat.filter(Boolean).join('')}, ${getDatePartValueByType('year', fromFormattedParts)}`;
  }

  const fromFormat: string[] = [];
  const toFormat: string[] = [];

  datePartsType.forEach((type) => {
    fromFormat.push(type === 'year' ? ', ' : ' ', getDatePartValueByType(type, fromFormattedParts));
    toFormat.push(type === 'year' ? ', ' : ' ', getDatePartValueByType(type, toFormattedParts));
  });

  return `${fromFormat.filter(Boolean).join('')} - ${toFormat.filter(Boolean).join('')}`;
}

function getDatePartValueByType(type: string, dateParts: Intl.DateTimeFormatPart[]): string {
  return dateParts.filter((part) => part.type === type)[0].value;
}
