'use client';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';

type AsyncFunction<T extends any[]> = (...args: T) => Promise<any>;

export function useDebouncedAsync<T extends any[]>(asyncFunction: AsyncFunction<T>, delay = 500) {
  const debouncedFunction = useCallback(
    debounce(async (...args: T) => {
      try {
        await asyncFunction(...args);
      } catch (error) {
        console.error('Async function error:', error);
      }
    }, delay),
    [asyncFunction, delay]
  );

  useEffect(() => debouncedFunction.cancel, [debouncedFunction]);

  return debouncedFunction;
}
