'use client';

import React from 'react';
import { classNames } from '../../utils/classNames';

export type SkeletonProps = {
  active?: boolean;
  className?: string;
  size?: '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xlg';
  wFull?: boolean;
  hFull?: boolean;
  width?: string;
  height?: string;
  children?: React.ReactNode;
};

export const Skeleton: React.FC<SkeletonProps> = (props) => {
  const { active = true, wFull, hFull, size = 'md', width, height, className, children } = props;

  return active ? (
    <div
      className={classNames(
        'inline-flex rounded bg-black/10 animate-pulse [&>*]:opacity-0',
        { 'w-full': wFull, 'h-full': hFull },
        !hFull &&
          !children && {
            'h-3': size === '3xs',
            'h-4': size === '2xs',
            'h-6': size === 'xs',
            'h-7': size === 'sm',
            'h-8': size === 'md',
            'h-10': size === 'lg',
            'h-12': size === 'xlg'
          },
        className
      )}
      style={{
        width,
        height
      }}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};
