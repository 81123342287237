import React from 'react';
import { classNameByAvatarSize } from './consts';
import { AvatarInitialsSize, AvatarSize } from './types';
import { classNames } from '../../utils/classNames';
import { generateBackgroundColor } from '../../utils/generateBackgroundColor';

export interface AvatarInitialsProps {
  /**
   * Classname for the avatar initials container
   * @default 'bg-white'
   * @type string
   * @optional
   */
  className?: string;
  /**
   * Classname for the text element
   * @type string
   * @optional
   */
  textClassName?: string;
  /**
   * Unique identifier for the avatar. This is used to generate a background color
   * @type number
   * @optional
   */
  id?: number;
  /**
   * Name of the user
   * @type string
   * @optional
   */
  name?: string;
  /**
   * Size of the avatar
   * @default 'md'
   * @type 'sm' | 'md' | 'lg'
   * @optional
   */
  size?: AvatarInitialsSize;
}

export interface AvatarProps {
  className?: string;
  id?: number;
  name?: string;
  color?: string;
  defaultColor?: string;
  size?: AvatarSize;
  Component?: string | React.FC<AvatarProps>;
  children?: React.ReactNode;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    size = 'md',
    id,
    defaultColor = 'bg-white',
    color = id ? generateBackgroundColor(id) : defaultColor,
    className,
    children,
    Component = 'div',
    ...rest
  } = props;

  return (
    <Component
      className={classNames(
        'inline-flex shrink-0 items-center justify-center rounded-full overflow-hidden cursor-default',
        color,
        classNameByAvatarSize[size],
        className
      )}
      {...rest}
    >
      {typeof children === 'string' ? (
        <span className='font-medium leading-none text-white'>{children}</span>
      ) : (
        children
      )}
    </Component>
  );
};

export const AvatarInitials: React.FC<
  AvatarInitialsProps & {
    /**
     * URL of the user's picture
     * @type string
     * @optional
     */
    picture?: string | null;
  }
> = (props) => {
  const { id, picture, size = 'md', name, className } = props;

  return (
    <Avatar size={size} id={id} className={className}>
      <Initials size={size} picture={picture} name={name} />
    </Avatar>
  );
};

export const Initials: React.FC<
  AvatarInitialsProps & {
    picture?: string | null;
  }
> = (props) => {
  const { picture, size = 'md', name } = props;
  const initial = name?.length ? name.substring(0, 1).toUpperCase() : '';

  return picture ? (
    <img src={picture} alt={'Your picture'} />
  ) : (
    <span className='font-medium leading-none text-white'>{initial}</span>
  );
};
