import React, { useId } from 'react';
import { TooltipProps } from './Tooltip';

export type TooltipConfigProps = TooltipProps & { id?: string; content: React.ReactNode };

export const composeTooltipRenderProps = (props: {
  id: string;
  tip?: React.ReactNode | React.ReactNode[] | Partial<TooltipConfigProps>;
  tipProps?: Partial<TooltipConfigProps>;
}): TooltipConfigProps | null => {
  const { tip, tipProps, id } = props;

  const finalTip =
    typeof tip === 'object' && tip?.content
      ? tip
      : ({
          content: tip
        } as TooltipConfigProps);

  finalTip.content = Array.isArray(finalTip.content) ? (
    <>
      {finalTip.content.map((p, i) => (
        <p key={i}>{p}</p>
      ))}
    </>
  ) : (
    finalTip.content
  );

  return tip
    ? {
        id,
        ...finalTip,
        ...(tipProps || {})
      }
    : null;
};

export const useToolTip = (
  tip: React.ReactNode | Partial<TooltipConfigProps>,
  tipProps?: Partial<TooltipConfigProps>
) => {
  const id = useId();
  const tooltip = composeTooltipRenderProps({ id: tip?.id || tipProps?.id || id, tip, tipProps });

  return tooltip;
};
