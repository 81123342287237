'use client';
import cookie from 'js-cookie';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

export type UseResizableElementProps = {
  minWidth?: number;
  defaultWidth?: number;
  maxWidth?: number;
  direction?: 'left' | 'right';
  storageKey?: string;
  cookieKey?: string;
  generation?: number;
  onStart?(): void;
  onEnd?(): void;
};
export const useResizableElement = (props: UseResizableElementProps) => {
  const {
    minWidth = 300,
    defaultWidth = 750,
    maxWidth = 1500,
    direction = 'left',
    storageKey,
    cookieKey,
    generation,
    onStart,
    onEnd
  } = props;

  const [storageWidth, setStorageWidth] = useLocalStorage(storageKey || '', null, { generation });
  const cookieWidth = cookie.get(cookieKey || '');

  const saveWidth = useMemo(() => debounce(setStorageWidth, 1000), []);

  const ref = useRef<HTMLElement>(null);
  const [width, setWidth] = useState<number | null>(cookieWidth || storageWidth || defaultWidth);
  const resizable = useRef<number | null>(width);
  const [isResizing, setIsResizing] = useState(false);
  const [staticWidth, setStaticWidth] = useState<number | null>(width);

  const [offset, setOffset] = useState(0);

  const handleWindowMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) {
        return;
      }

      setWidth((previousWidth) => {
        const newWidth = direction === 'left' ? offset - e.clientX : e.clientX;

        const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth;

        const finalWidth = isWidthInRange ? newWidth : previousWidth;

        resizable.current = finalWidth;

        return finalWidth;
      });
    },
    [direction, isResizing, offset]
  );

  const handleHandlerMouseDown = useCallback(() => {
    setIsResizing(true);
    setOffset(ref.current?.getBoundingClientRect()[direction === 'left' ? 'right' : 'left'] || 0);
    onStart?.();
  }, []);

  const handleWindowMouseUp = useCallback(() => {
    setIsResizing(false);
    onEnd?.();
  }, []);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleWindowMouseMove);
      window.addEventListener('mouseup', handleWindowMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
      window.removeEventListener('mouseup', handleWindowMouseUp);
    };
  }, [isResizing, handleWindowMouseMove, handleWindowMouseUp]);

  useEffect(() => {
    if (staticWidth === null) {
      return;
    }

    if (storageKey) {
      saveWidth(staticWidth);
    }
    if (cookieKey) {
      cookie.set(cookieKey, staticWidth);
    }
  }, [staticWidth]);

  useEffect(() => {
    setWidth((prev) => Math.min(prev, maxWidth));
  }, [maxWidth]);

  useEffect(() => {
    if (!isResizing) {
      setStaticWidth(width);
    }
  }, [isResizing, width]);

  return {
    ref,
    width,
    staticWidth,
    isResizing,
    handleHandlerMouseDown
  };
};
