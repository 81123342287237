import { AnimatePresence, MotionProps, motion } from 'framer-motion';
import React from 'react';

export type CollapsibleProps = React.ComponentProps<'div'> & { expanded?: boolean; id?: string };

export const Collapsible: React.FC<CollapsibleProps> = ({
  id,
  children,
  expanded = Boolean(children),
  ...props
}) => (
  <AnimatePresence initial={false}>
    {expanded ? (
      <motion.div
        key={id}
        animate={{ height: 'fit-content' }}
        initial={{ height: 0 }}
        exit={{ height: 0 }}
        transition={{ duration: 0.15 }}
        {...(props as MotionProps)}
      >
        {children}
      </motion.div>
    ) : null}
  </AnimatePresence>
);
