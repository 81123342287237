'use client';
import { useCallback, useState } from 'react';

export const useSessionStorage = (
  key: string,
  initialValue: unknown,
  opts: { generation?: string | number } = {}
) => {
  const generation = opts?.generation;

  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (typeof window === 'undefined') {
        // console.warn('Can`t use session storage with SSR. Initial value will be used.');

        return initialValue;
      }

      const item = window && JSON.parse(window.sessionStorage.getItem(key));

      if (item && (typeof generation === 'undefined' || item?.generation === generation)) {
        return item.value;
      }

      return initialValue;
    } catch (error) {
      console.error(error);

      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      try {
        setStoredValue(value);

        if (window && window.sessionStorage) {
          sessionStorage.setItem(
            key,
            JSON.stringify({
              generation,
              value
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [key, generation]
  );

  return [storedValue, setValue];
};

export default useSessionStorage;
