import React from 'react';
import { Button, ButtonProps } from 'react-aria-components';
import { XMarkIcon } from '@heroicons/react/24/outline';

export type ModalCloseButtonProps = ButtonProps;

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = (props) => {
  return (
    <Button
      type='button'
      className='rounded-md bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      {...props}
    >
      <span className='sr-only'>Close</span>
      <XMarkIcon className='h-6 w-6' aria-hidden='true' />
    </Button>
  );
};
