import React, { ComponentProps } from 'react';
import { classNames } from '../../utils';

export interface BadgeProps extends ComponentProps<'div'> {
  /**
   * Classname for the badge
   * @type string
   * @optional
   */
  className?: string;
  /**
   * Color of the badge
   * @type 'callout' | 'primary' | 'neutral'
   * @default 'neutral'
   * @optional
   */
  color?: 'callout' | 'primary' | 'neutral';
  // TODO: Need types
  type?: string;
  // TODO: Need types
  size?: 'sm' | 'base' | string;
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const { color, type = 'default', size = 'base', ...rest } = props;

  return (
    <div
      {...rest}
      className={classNames(
        'flex min-w-fit w-fit rounded py-1 px-2 text-xs whitespace-nowrap shadow-sm border truncate font-medium cursor-default',
        {
          'bg-callout-1 border-callout-8/30 text-callout-8': color === 'callout',
          'bg-primary-2 border-primary-2 text-primary-7': color === 'primary',
          'bg-gray-200 border-gray-200 text-gray-700': color === 'neutral'
        },
        {
          'rounded-full': type === 'rounded',
          '': type === 'default'
        },
        {
          'text-[0.6rem]': size === 'sm',
          'text-xs': size === 'base'
        },
        props.className
      )}
    />
  );
};
