import React from 'react';
import { ModalOverlay, ModalOverlayProps } from '../Modal';

export type SidebarOverlayProps = ModalOverlayProps;

export const SidebarOverlay: React.FC<SidebarOverlayProps> = (props) => {
  const { ...rest } = props;

  return <ModalOverlay {...rest} />;
};
