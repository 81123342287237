import React, { LegacyRef, forwardRef } from 'react';
import { Tooltip as BaseTooltip, ITooltip, TooltipRefProps } from 'react-tooltip';
import { classNames } from '../../utils/classNames';

export type TooltipProps = ITooltip & {
  title?: React.ReactNode;
};

const closeEvents = {
  mouseout: true,
  blur: true,
  mouseleave: true,
  click: true,
  dblclick: false,
  mouseup: false
};

export const Tooltip: React.FC<TooltipProps> = forwardRef((props, ref) => {
  const { variant = 'dark', className, children, title, content = children, ...rest } = props;

  return title || content ? (
    <BaseTooltip
      ref={ref as LegacyRef<TooltipRefProps>}
      delayShow={300}
      opacity={0.96}
      closeEvents={closeEvents}
      className={classNames(
        'rounded !p-3 !z-50 !text-sm !font-normal max-w-[80vw] sm:max-w-lg',
        {
          '!bg-white !shadow-2xl !text-black': variant === 'light'
        },
        className
      )}
      {...rest}
    >
      <div className={'space-y-1 max-w-full font-medium'}>
        {typeof title === 'string' ? <TooltipTitle>{title}</TooltipTitle> : title}
        {typeof content === 'string' ? <TooltipBody>{content}</TooltipBody> : <>{content}</>}
      </div>
    </BaseTooltip>
  ) : null;
});

export const TooltipTitle: React.FC<React.ComponentProps<'h3'>> = (props) => (
  <h3 className={classNames('truncate text-sm font-semibold text-white', props.className)}>
    {props.children}
  </h3>
);
export const TooltipBody: React.FC<React.ComponentProps<'p'>> = (props) => (
  <p
    className={classNames(
      'max-w-full whitespace-break-spaces text-start font-medium',
      props.className
    )}
  >
    {props.children}
  </p>
);
