import React from 'react';
import { ForwardRef } from 'react-is';
import { Icon } from './Icon';
import { ButtonLoader } from '../Button/ButtonLoader';

export const getIcon = (props: {
  isLoading?: boolean;
  loader?: React.FC<any>;
  icon?: React.ReactNode | React.FC<any> | typeof ForwardRef;
  iconProps?: { className?: string };
}) => {
  const { isLoading, loader: Loader = ButtonLoader, icon, iconProps } = props;

  return isLoading ? (
    <div className={'flex justify-center items-center'}>
      <Loader />
    </div>
  ) : icon ? (
    <Icon icon={icon} iconProps={iconProps} />
  ) : null;
};
