import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { classNames } from '../../../utils/classNames';

export interface MenuSearchEmptyResultProps {
  className?: string;
}

export const MenuSearchEmptyResult: React.FC<MenuSearchEmptyResultProps> = (props) => (
  <div
    className={classNames(
      'flex gap-x-2 px-4 pt-2 pb-4 items-center text-sm text-gray-500',
      props.className
    )}
  >
    <ExclamationCircleIcon className={'w-4 h-4 text-gray-500'} />
    No results found
  </div>
);
