import React from 'react';
import { classNames } from '../../utils/classNames';

export interface ProgressbarProps {
  percentage: number;
  className?: string;
}

export const Progressbar: React.FC<ProgressbarProps> = (props) => {
  const percentage = props.percentage;

  return (
    <div className={classNames('bg-neutral-2 rounded-full h-3 overflow-hidden', props.className)}>
      {percentage >= 0 ? (
        <div
          className={classNames(
            'h-full bg-primary-5 text-xs font-medium text-center p-0.5 leading-none ',
            percentage !== 100 ? 'animate-pulse' : ''
          )}
          style={{ width: `${percentage}%`, minWidth: '2px' }}
        />
      ) : null}
    </div>
  );
};
