import React from 'react';
import { classNames } from '../../utils/classNames';
import { MenuSearchField, MenuSearchFieldProps } from '../Menu';

export type DropdownMenuSearchFieldProps = MenuSearchFieldProps;

export const DropdownMenuSearchField: React.FC<MenuSearchFieldProps> = (props) => {
  return (
    <MenuSearchField
      {...props}
      className={classNames('bg-white p-2 pb-0 sticky top-0 z-10', props.className)}
    />
  );
};
