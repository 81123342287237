import { createCalendar } from '@internationalized/date';
import { AriaDatePickerProps, DateValue } from '@react-types/datepicker';
import { useDateFieldState } from 'react-stately';
import { DateFormat, formatDateRange } from '#/packages/ui';

export interface UseDateRangeProps {
  start: AriaDatePickerProps<DateValue>;
  end: AriaDatePickerProps<DateValue>;
  dateFormat?: DateFormat;
}

export const useDateRange = (props: UseDateRangeProps) => {
  const { start, end, dateFormat = {} } = props;

  const startState = useDateFieldState({
    ...start,
    locale: 'en-GB',
    createCalendar
  });
  const endState = useDateFieldState({
    ...end,
    locale: 'en-GB',
    createCalendar
  });

  const label = startState
    ? formatDateRange(new Date(startState.dateValue), new Date(endState.dateValue), {
        month: 'short',
        ...dateFormat,
        locale: 'en-GB',
        timeZone: 'UTC'
      })
    : '';

  return {
    startState,
    endState,
    label
  };
};
