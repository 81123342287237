'use client';
import React, { ReactNode, useId } from 'react';
import enUS from 'date-fns/locale/en-US';
import { formatDistanceStrict, formatDistance, intlFormatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { roundFormatDistance } from '../../utils/date';
import { useCurrentTime, useTimeStamp } from '../../hooks';
import { Tooltip } from '../Tooltip';

type formatTypes = 'strict' | 'helpers' | 'human' | 'round';

export type RelativeTimeStampProps = {
  time: number | Date;
  format?: formatTypes;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
  nowSuffix?: string | ReactNode;
  tooltip?: boolean | string;
  live?: boolean;
};

const getRelativeLabelByFormat = (
  format: formatTypes,
  zonedTime: number | Date,
  now: number | Date
) => {
  switch (format) {
    case 'strict': {
      return formatDistanceStrict(zonedTime, now, { locale: enUS });
    }
    case 'helpers': {
      return formatDistance(zonedTime, now, { locale: enUS });
    }
    case 'round': {
      return roundFormatDistance(zonedTime, now);
    }
    default: {
      return intlFormatDistance(zonedTime, now, { locale: enUS.code });
    }
  }
};

export const useRelativeTimeTooltipTimeStamp = (props) =>
  useTimeStamp({
    time: props.time,
    timeZone: props.timezone,
    dateFormat: 'do MMMM yyyy',
    timeFormat: 'HH:mm a',
    zoneFormat: null,
    divider: ', '
  });

export const RelativeTimeStamp: React.FC<RelativeTimeStampProps> = (props) => {
  const {
    live = false,
    time,
    prefix,
    suffix,
    nowSuffix = '',
    format = 'human',
    timezone,
    tooltip,
    Component = 'span',
    ...rest
  } = props;

  const id = useId();
  const currentTime = useCurrentTime({ live });
  const relativeTime = useCurrentTime({ time });
  const zonedTime = timezone ? utcToZonedTime(relativeTime, timezone) : relativeTime;

  const timestampLabel = getRelativeLabelByFormat(format, zonedTime, currentTime);
  const label = [prefix, timestampLabel, timestampLabel.endsWith('now') ? nowSuffix : suffix]
    .filter(Boolean)
    .reduce((prev, curr) => [prev, ' ', curr]);

  const { timestamp } = useTimeStamp({
    time,
    timeZone: timezone,
    dateFormat: 'do MMMM yyyy',
    timeFormat: 'HH:mm a',
    zoneFormat: null,
    divider: ', '
  });

  return (
    <>
      <Component data-tooltip-id={tooltip && id} {...rest}>
        {label}
      </Component>
      {tooltip ? (
        <Tooltip id={id} content={typeof tooltip === 'string' ? tooltip : timestamp} />
      ) : null}
    </>
  );
};

export default RelativeTimeStamp;
