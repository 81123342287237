import { IMenuItemsGroup, MenuItems } from '.';

export const matchMenuItem = (label: any, filter: string) =>
  label && typeof label === 'string'
    ? new RegExp(filter.replaceAll(' ', '').split('').join('.*'), 'mi').test(label)
    : false;

export const buildMenuItemGroups = (options: MenuItems): IMenuItemsGroup[] =>
  Object.entries(
    options.reduce(
      (groups, option) => {
        return {
          ...groups,
          [option.group || '']: [...(groups[option.group || ''] || []), option]
        };
      },
      {} as Record<string, MenuItems>
    )
  ).map(([header, items]) => ({ items, header: header || null }));
