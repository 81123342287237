import React, { useMemo } from 'react';
import type { Selection } from 'react-aria-components';
import { Menu as BaseMenu } from 'react-aria-components';
import { MenuSection, MenuSectionProps } from './MenuSection';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { IMenuItemsGroup, MenuItems, MenuKey, MenuSelectionMode } from './types';
import { buildMenuItemGroups } from './utils';
import { classNames } from '../../utils/classNames';

export interface MenuProps
  extends Pick<MenuSectionProps, 'withCheckMark' | 'MenuSectionHeaderComponent'> {
  size?: 'sm' | 'md' | 'lg';
  items?: MenuItems;
  groups?: IMenuItemsGroup[];
  selectionMode?: MenuSelectionMode;
  selectedKeys?: MenuKey[] | Set<MenuKey>;
  disabledKeys?: MenuKey[] | Set<MenuKey>;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  autoFocus?: boolean;
  className?: string;
  emptyList?: React.ReactNode;
  children?: React.ReactNode;
  onAction?: (key: MenuKey) => void;
  onSelectionChange?: (keys: Selection) => void;
  sectionClassName?: string;
}

export const Menu: React.FC<MenuProps> = (props) => {
  const {
    size,
    items = [],
    groups: initialGroups,
    children,
    hiddenKeys = [],
    emptyList = 'No items',
    withCheckMark,
    sectionClassName,
    MenuSectionComponent = MenuSection,
    MenuSectionHeaderComponent,
    ...rest
  } = props;

  const groups: IMenuItemsGroup[] = useMemo(() => {
    const groups = initialGroups || buildMenuItemGroups(items);

    return groups.map((group) => {
      if (group === MenuSeparatorPlaceholder) {
        return group;
      }

      return {
        ...group,
        hidden:
          group.hidden ||
          group.items.every(
            (item) =>
              item !== MenuSeparatorPlaceholder &&
              Array.from(hiddenKeys).includes(item.id as MenuKey)
          )
      };
    });
  }, [items, hiddenKeys, initialGroups]);

  const allHidden = groups.every((group) => group === MenuSeparatorPlaceholder || group.hidden);
  const firstNonHiddenGroupIndex = groups.findIndex(
    (group) => group !== MenuSeparatorPlaceholder && !group.hidden
  );
  const lastNonHiddenGroupIndex = groups.findLastIndex(
    (group) => group !== MenuSeparatorPlaceholder && !group.hidden
  );

  return (
    <>
      {allHidden ? (
        typeof emptyList === 'string' ? (
          <div className={'px-4 py-2'}>
            <span className={'text-gray-500 text-sm'}>{emptyList}</span>
          </div>
        ) : (
          emptyList
        )
      ) : null}
      <BaseMenu {...rest}>
        {children || (
          <>
            {groups.map((group, i) => {
              return group === MenuSeparatorPlaceholder ? (
                <MenuSeparator key={i} />
              ) : (
                <React.Fragment key={i}>
                  {i === 0 ||
                  Boolean(group.header) ||
                  group.hidden ||
                  group.separator === null ? null : (
                    <MenuSeparator />
                  )}
                  <MenuSectionComponent
                    size={size}
                    items={group.items}
                    header={group.header}
                    hiddenKeys={hiddenKeys}
                    selectionMode={rest.selectionMode}
                    withCheckMark={withCheckMark}
                    MenuSectionHeaderComponent={MenuSectionHeaderComponent}
                    className={classNames(
                      sectionClassName,
                      group.className,
                      i === firstNonHiddenGroupIndex ? '' : 'border-t border-gray-100',
                      i === firstNonHiddenGroupIndex ? 'pt-2 [&>header]:mt-0' : '',
                      i === lastNonHiddenGroupIndex ? 'pb-2' : ''
                    )}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}
      </BaseMenu>
    </>
  );
};
