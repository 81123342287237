import React from 'react';
import { Dialog, DialogProps } from 'react-aria-components';
import { classNames } from '../../utils/classNames';

export type SidebarDialogProps = DialogProps;

export const SidebarDialog: React.FC<SidebarDialogProps> = (props) => (
  <Dialog
    role='dialog'
    {...props}
    className={classNames(
      'outline-none relative h-full max-w-full bg-white overflow-y-auto overflow-x-hidden',
      props.className
    )}
  />
);
