'use client';
import React from 'react';
import { ModalProps } from '../Modal/types';
import { SidebarResizeHandler } from './SidebarResizeHandler';
import { SidebarOverlay } from './SidebarOverlay';
import { SidebarDialog } from './SidebarDialog';
import { SidebarContainer } from './SidebarContainer';
import { SidebarHeader } from './SidebarHeader';
import { SidebarHeading } from './SidebarHeading';
import { SidebarCloseButton } from './SidebarCloseButton';
import { SidebarBody } from './SidebarBody';

export interface SidebarProps extends ModalProps {
  resizeStateStorageKey?: string;
  width?: string;
  isResizing?: boolean;
  onResize?: () => void;
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const {
    show,
    title,
    animate = 'slide',
    position = 'right',
    isDismissable = true,
    width = '300px',
    withClose,
    isResizing,
    children,
    onClose,
    onResize
  } = props;

  return (
    <SidebarOverlay
      isOpen={show}
      isDismissable={isDismissable}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <SidebarContainer width={width} position={position} animate={animate}>
        <SidebarDialog>
          {({ close }) => (
            <>
              {title || withClose ? (
                <SidebarHeader>
                  {title ? <SidebarHeading>{title}</SidebarHeading> : null}
                  {withClose ? <SidebarCloseButton onPress={close} /> : null}
                </SidebarHeader>
              ) : null}

              <SidebarBody>{children}</SidebarBody>

              {onResize ? (
                <SidebarResizeHandler isResizing={isResizing} onResize={onResize} />
              ) : null}
            </>
          )}
        </SidebarDialog>
      </SidebarContainer>
    </SidebarOverlay>
  );
};

export default Sidebar;
